


// 我们后面再讨论嵌套路由。
export const routes = [
    {
        path: '/',
        redirect: () => {
            return 'personal'
        },
        children: [
            {
                path: 'personal',
                component: () => import("../views/homelab/personal.vue"),
            },
            
            {
                path: 'company',
                component: () => import("../views/homelab/company.vue"),
            },
            {
                path: 'software',
                component: () => import("../views/homelab/software.vue"),
            },
            {
                path: 'hardware',
                component: () => import("../views/homelab/hardware.vue"),
            },
            {
                path: 'service',
                component: () => import("../views/homelab/service.vue"),
            },
        ],
    }
]

