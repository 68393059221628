<template>
  <div class="appCon">
    <el-container class="containerBox">
      <el-header class="headCon">
        <div class="navTitle">HomeLab</div>
      </el-header>
      <el-container>
        <el-aside class="asideCon">
          <el-menu default-active="1-1">
            <el-sub-menu index="1">
              <template #title>
                <span>Scenes</span>
              </template>
              <el-menu-item index="1-1" @click="linkTo(`/personal`)"
                >personal</el-menu-item
              >
              <el-menu-item index="1-2" @click="linkTo(`/company`)"
                >company</el-menu-item
              >
            </el-sub-menu>
            <el-sub-menu index="2">
              <template #title>
                <span>Type</span>
              </template>
              <el-menu-item index="2-2" @click="linkTo(`/hardware`)"
                >hardware</el-menu-item
              >
              <!-- <el-menu-item index="2-1" @click="linkTo(`/software`)"
                >software</el-menu-item
              > -->
            </el-sub-menu>
            <el-sub-menu index="3">
              <template #title>
                <span>Service</span>
              </template>
              <el-menu-item index="3-2" @click="linkTo(`/service`)"
                >service</el-menu-item
              >
            </el-sub-menu>
          </el-menu>
        </el-aside>
        <el-container class="mainCon">
          <el-main>
            <router-view></router-view>
          </el-main>
          <el-footer class="footCon"></el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  methods: {
    linkTo(path = `/`) {
      console.log(`跳转页面`);
      this.$router.push({ path: path });
    },
  },
};
</script>
<style>
body {
  margin: 0;
  padding: 0;
}
</style>
<style lang="less" scoped>
.appCon {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  .containerBox {
    width: 100%;
    height: 100%;
    .headCon {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      // background-color: #ccc;
      .navTitle {
      }
    }
    .asideCon {
      width: 220px;
      height: 100%;
      border: 1px solid #eee;
      // background-color: aquamarine;
    }
    .mainCon {
      width: 100%;
      border: 1px solid #eee;
      // background-color: aqua;
    }
    .footCon {
      width: 100%;
      height: 0;
      // border: 1px solid #eee;
      // background-color: antiquewhite;
    }
  }
}
</style>
