// 引入vue
import { createApp } from 'vue'
import App from './App.vue'
// 引入element
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 引入路由
import { createRouter, createWebHashHistory } from "vue-router";
import { routes } from "./router/index"

// 设置路由
export const router = createRouter({
    // hash 模式。
    history: createWebHashHistory(),
    routes,
})

// 注册app
const app = createApp(App)
// 注册图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
//注册路由
app.use(router)
// 注册element
app.use(ElementPlus)
// 挂载元素
app.mount('#app')
